import React from "react"

import GridLayout from "../Layout/GridLayout"
import { useI18next } from "../i18n"

import { Breadcrumbs } from "../Layout/Components"
import Blog, { PostData, CategoryData, TagData } from "../templates/Blog"

import { Seo } from "../seo"
import siteMap from "../sitemap"

const NotFoundPage = () => {
  const { t } = useI18next()
  return (
    <GridLayout>
      <Breadcrumbs data={[{ to: "/", label: t("Home") }, { label: t("404 Not Found") }]} />
    </GridLayout>
  )
}

export default NotFoundPage
